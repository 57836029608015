<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3" v-if="!app_id">
                  <label for="validationcategory">
                    {{ cvCategoryLabel }}</label><br/>
                    <select v-model="vmQuestionBankFormData.app_category" class="form-control">
                      <option disabled value="">Please select one</option>
                      <option v-for="item in vmqbkExamCategoryList" :key="item.app_category">{{ item.app_category }}</option>
                    </select>
                </div>
                <div class="col-md-12 mb-3" v-else>
                  <label for="validationcategory">
                    {{ cvCategoryLabel }}</label><br/>
                  <input v-model="vmQuestionBankFormData.app_category" type="text" class="form-control" required disabled/>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcategory">Select Type</label><br/>
                  <select v-model="vmQuestionBankFormData.is_mcq" class="form-control">
                    <option :value="1">MCQ</option>
                    <option :value="0">ESSAY TYPE</option>
                  </select>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationquestion">
                    {{ cvQuestionLabel }}</label>
                    <vue-editor class="katex" :editorToolbar="customToolbarAdmin" v-model="vmQuestionBankFormData.question"></vue-editor>
                </div>

                <div class="col-md-12 mb-3" v-if="vmQuestionBankFormData.difficulty">
                  <label for="validationquestion">
                    {{ cvDifficultyLabel }}</label>
                    <select class="form-control" v-model="vmQuestionBankFormData.difficulty">
                      <option value="easy">Easy</option>
                      <option value="medium">Medium</option>
                      <option value="hard">Hard</option>
                    </select>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationquestion">
                    {{ cvSkillLabel }}</label>
                  <input v-model="vmQuestionBankFormData.skill" type="text" class="form-control" />
                </div>

                <div class="mt-4 col-md-12">
                  <h4 class="mb-3">
                    {{ cvPBACoverPic }} : {{ imageSize }}
                  </h4>

                  <div class="form-group row align-items-center">
                    <div class="col-md-12">
                      <div class="profile-img-edit border" style="height: 200px; width: 300px;">
                        <img v-if="vmCoverPic" :src="vmCoverPic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: cover; overflow: hidden;" />
                        <i v-else class="fas fa-calendar d-flex justify-content-center align-items-center" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                        <label class="p-image">
                          <i class="ri-pencil-line upload-button" style="cursor: pointer;"></i>
                          <input class="file-upload" type="file" required accept="image/png image/jpeg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event, 'Cover Pic')" />
                        </label>
                        <span class="text-danger" ref="coverPicError"></span>
                      </div>
                    </div>
                  </div>

                  <button type="button" size="sm" class="btn btn-primary" @click="removeImage(propQuestionBankObj.qbk_id)">Remove Image</button>

                  <!-- Add Answer options -->
                  <template v-if="vmQuestionBankFormData.is_mcq == 1">
                    <div class="form-group col-12 markRow mt-4">
                    <div> <label for="correctedOption">{{cvoptionAnswersLabel}}</label></div>
                      <span class="addIcon pointer" @click="addOption()">Add Option <i class="fa-solid fa-circle-plus"></i></span>
                    </div>
                    <div :key="update" class="row w-100">
                      <div class="form-group w-100">
                        <template v-for="(val, option) in vmQuestionBankFormData.answer_choices" >
                          <div class="col-12 answerOption mt-3 mb-3 d-block" :key="option">
                            <span class="badge bg-primary text-white rounded-pill optionAddSpan">{{option}}</span>
                            <span class="addIcon answerOptionAddBtn" @click="removeOption(option)"><i class="fa-solid fa-circle-xmark"></i></span>
                            <vue-editor class="katex" :editorToolbar="customToolbarAdmin" v-model="vmQuestionBankFormData.answer_choices[option]" ></vue-editor>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                  <!-- Add Answer options end -->

                  <!-- Correct Answer -->
                  <div class="col-md-12 mb-3">
                    <label for="validationcorrect_answers">
                      {{ cvCorrectAnswersLabel }}
                    </label>
                    <input v-model="vmQuestionBankFormData.correct_answers" type="text" class="form-control" required />
                  </div><!-- Correct Answer -->

                  <!-- Answer explanation -->
                  <div class="col-md-12 mb-3 mt-3">
                    <div><label for="cvExplanation">{{cvLabelExplanation}}</label></div>
                    <vue-editor class="katex" :editorToolbar="customToolbarAdmin" v-model="vmQuestionBankFormData.answer_explanation" :key="update_answer_explanation"></vue-editor>
                  </div><!-- Answer explanation -->

                  <div class="col-md-12 mb-3 mt-3">
                    <label for="validationqbkcategory">{{ cvQbkCategoryLabel }}</label><br/>
                    <select v-model="vmQuestionBankFormData.qbk_category_option" class="form-control">
                      <option disabled value="">Please select one</option>
                      <option v-for="item in vmqbkcategoryList" :key="item.qbk_category">{{ item.qbk_category }}</option>
                      <option value="AddNew">Add New</option>
                    </select>
                    <div v-if="vmQuestionBankFormData.qbk_category_option ==='AddNew' ">
                      <input v-model="vmQuestionBankFormData.qbk_category" placeholder="Enter the question bank category" type="text" class="form-control" required />
                    </div>
                  </div>

                  <div class="col-12 mb-3 gide_inline_textorcheckbox">
                    <label class="lable" for="app_has_calc">{{ cvExamCalcReq }}</label>
                    <ValidationProvider
                      name="Show Calculator for this Question ?"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <input id="" v-model="vmQuestionBankFormData.has_calc" type="checkbox" class="form-control" required />
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="quizBankEdit()">
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style lang="scss" scoped>
  div.profile-img-edit{
    width: 250px;
    height: 250px;
    display: block !important;
  }
    .fa-circle-plus{
    font-size: 16px;
    position: relative;
    top: 1px;
    float: right;
    color: #fb0000;
  }
  .answerOption {
    position: relative;
  }
  .answerOptionAddBtn {
    position: absolute;
    font-size: 20px;
    top: 7px;
    right: 5px;
  }
  .form-group {
    input {
      margin-left: 10px;
    }
  }
  .optionAddSpan {
    position: relative;
    height: 20px;
    top: 10px;
  }
</style>
<script>
import { QuizBanks } from "../../../FackApi/api/quizBank.js"
import { Apps } from "../../../FackApi/api/apps.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import CropImage from "../../../components/cropImage.vue"
import { VueEditor } from "vue2-editor"
import userPermission from "../../../Utils/user_permission"
import { socialvue } from "../../../config/pluginInit.js"
import "katex/dist/katex.min.css"
import katex from "katex"
import { base64encode, base64decode } from "nodejs-base64"

export default {
  name: "QuestionBankEdit",
  components: {
    CropImage,
    VueEditor
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propQuestionBankObj: {
      type: Object,
      default: function () {
        return {
          "is_mcq": 1,
          "app_category": "",
          "question": "",
          "correct_answers": "",
          "answer_choices": {}
        }
      }
    }
  },
  data () {
    return {
      apiName: "quiz_bank_edit",
      cvCardTitle: "Edit Question",
      cvSubmitBtn: "Edit",
      cvCategoryLabel: "Quiz Category",
      cvLabelExplanation: "Answer Explanation",
      cvQbkCategoryLabel: "Question Bank Category",
      cvQuestionLabel: "Question",
      cvDifficultyLabel: "Difficulty",
      cvSkillLabel: "Skill or Tag",
      cvAnswerChoicesLabel: "Answer Choices",
      cvCorrectAnswersLabel: "Correct Answers",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Question Bank",
      cvoptionAnswersLabel: "Options Answers",
      vmQuestionBankFormData: {},
      vmqbkExamCategoryList: " ",
      cvPBACoverPic: "Edit Question Picture",
      vmCoverPic: "",
      propCropImageDialogOpen: false,
      propCropDialogSize: "md",
      propStencilSize: null,
      fileSrc: "",
      cvImageExtensionErrorMsg: " Only jpeg, webp and png is supported.",
      cvImageSizeErrorMsg: " Image size must be less than 4 mb.",
      updateImageType: null,
      vmProfilePic: "",
      vmqbkcategoryList: " ",
      answerChoiceObj: {},
      answerChoiceArr: [],
      app_id: "",
      cvCoverPicLoading: false,
      cvCoverPicError: false,
      isPhotoUploaded: false,
      imageSize: "Size  1080 * 566",
      cvExamCalcReq: "Show Calculator for this Question ?",
      update: 0,
      customToolbarAdmin: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image", "video", "formula"],
        ["clean"] // remove formatting button
      ],
      update_answer_explanation: 0
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    window.katex = katex

    if (this.$route.params.app_id) {
      this.app_id = this.$route.params.app_id
    }

    this.appCategoryDistinct()
    this.qbkCategory()

    this.quizBankView()
  },
  methods: {
    /**
     * openImageDialog
     *
     */
    openImageDialog (e, imageType) {
      this.isPhotoUploaded = true
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 4 * 1024 * 1024
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          return false
        }
        this.propStencilSize = {
          "width": 540,
          "height": 283
        }
        this.propCropDialogSize = "md"
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * removeImage
     */
    async removeImage (quizBankId) {
      const imageUpdateResp = await QuizBanks.imageDeleteFromQuizBank(this, quizBankId)

      if (imageUpdateResp.resp_status) {
        this.vmCoverPic = ""
        return true
      }
      else {
        this.toastMsg = "Error: Unable to delete the image"
        this.toastVariant = "danger"
        this.showToast = true
        return false
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let orgImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      this.vmPBACoverPicImage = Object.assign({}, this.initImageData())
      this.vmPBACoverPicImage.image = {
        "img": img,
        "name": orgImg.name
      }
      this.vmPBACoverPicImage.image_type = "cover_pic"
    },
    /**
     * onChangeProfileImage
     */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * initImageData
     */
    initImageData () {
      return {
        image: "",
        image_type: ""
      }
    },
    /**
     * initPBAData
     */
    initPBAData () {
      return {
        question_name: "",
        module_id: ""
      }
    },
    /**
     * imageAdd
     */
    async imageAdd (pbaId) {
      if (this.cvImageError) {
        return
      }
      try {
        this.cvImageLoadingStatus = true
        let imageObj = {
          "module_name": "QUIZBANK",
          "module_id": pbaId,
          "image": []
        }

        if (this.vmPBACoverPicImage.image) {
          imageObj.image.push(this.vmPBACoverPicImage)
        }

        if (imageObj.image.length < 1) {
          return false
        }
        const imageUpdateResp = await QuizBanks.imageAddToQuizBank(this, imageObj)
        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          return false
        }
        this.vmPBACoverPicImage = Object.assign({}, this.initPBAData())
        imageObj = {}
        return imageUpdateResp
      }
      catch (err) {
        console.error("Exception occurred at imageAdd() and Exception:", err.message)
        return err
      }
      finally {
        this.cvImageLoadingStatus = false
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmQuestionBankFormData) {
          if (!this.vmQuestionBankFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * quizBankView
     */
    async quizBankView () {
      try {
        let quizBankId = this.propQuestionBankObj.qbk_id ? this.propQuestionBankObj.qbk_id : this.$route.params.qbk_id
        let quizBankViewResp = await QuizBanks.quizBankView(this, quizBankId)

        if (quizBankViewResp && quizBankViewResp.resp_status) {
          this.vmQuestionBankFormData = quizBankViewResp.resp_data.data
          // Quiz Images
          if (this.vmQuestionBankFormData.image) {
            this.vmCoverPic = this.vmQuestionBankFormData.image
          }

          if (this.vmQuestionBankFormData.answer_explanation) {
            var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
            this.vmQuestionBankFormData.answer_explanation = base64regex.test(this.vmQuestionBankFormData.answer_explanation) ? base64decode(this.vmQuestionBankFormData.answer_explanation) : this.vmQuestionBankFormData.answer_explanation
            // this.vmQuestionBankFormData.answer_explanation = this.vmQuestionBankFormData.answer_explanation.replace(/\n\n/g, "<br/><br/>").replace(/\\/g, "")
            /*
             * For Future Use to detect LaTex Script. ~ => Space, //=> Newline.
             * Currently can't enter multiple new lines as Katex does not recognise it
             * IS_LATEX_RESPONSE needs to be added in the GPT Response
             */
            let answerExplanationLatex = ""
            if (this.vmQuestionBankFormData.answer_explanation.includes("IS_LATEX_RESPONSE")) {
              this.vmQuestionBankFormData.answer_explanation = this.vmQuestionBankFormData.answer_explanation.replace("IS_LATEX_RESPONSE", "")
              answerExplanationLatex = this.vmQuestionBankFormData.answer_explanation.replace(/ /g, "~").replace(/\\\[/g, "").replace(/\\\]/g, "").replace(/\\\(/g, "").replace(/\\\)/g, "").replace(/\n/g, "\\")
              answerExplanationLatex = katex.renderToString(answerExplanationLatex, { throwOnError: false })
              this.vmQuestionBankFormData.answer_explanation = answerExplanationLatex
            }

            this.vmQuestionBankFormData.answer_explanation = this.vmQuestionBankFormData.answer_explanation.replace("IS_LATEX_RESPONSE", "")
            this.vmQuestionBankFormData.answer_explanation = this.vmQuestionBankFormData.answer_explanation.replace(/\n/g, "<br/>").replace(/\\/g, "")
          }

          this.update_answer_explanation = this.update_answer_explanation + 1

          this.vmQuestionBankFormData.qbk_category_option = this.vmQuestionBankFormData.qbk_category ? this.vmQuestionBankFormData.qbk_category : null
        }
      }
      catch (err) {
        console.error("Exception occurred at quizBankView() and Exception:", err.message)
      }
    },
    /**
     * addOption
     */
    addOption () {
      if (!this.vmQuestionBankFormData.answer_choices) {
        this.vmQuestionBankFormData.answer_choices = {}
      }

      this.update += 1
      let index = Object.keys(this.vmQuestionBankFormData.answer_choices).length + 1
      let char = String.fromCharCode(96 + index)
      this.vmQuestionBankFormData.answer_choices[char] = ""
    },
    /**
     * removeOption
     * @Params optionNo that need to remove
     * first delete the option then all obj values in sorting order
     * like a,b,c,d and c remove then d will come at place of c
     */
    removeOption (optionNo) {
      let op = {}
      delete this.vmQuestionBankFormData.answer_choices[optionNo]
      const keys = Object.keys(this.vmQuestionBankFormData.answer_choices)

      for (let index = 0; index < keys.length; index++) {
        const char = String.fromCharCode(96 + index + 1)
        op[char] = this.vmQuestionBankFormData.answer_choices[keys[index]]
      }

      this.update += 1
      this.vmQuestionBankFormData.answer_choices = { ...op }
    },
    /**
     * quizBankEdit
     */
    async quizBankEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        if (this.vmQuestionBankFormData.qbk_category_option !== "AddNew") {
          this.vmQuestionBankFormData.qbk_category = this.vmQuestionBankFormData.qbk_category_option
        }

        if (this.vmQuestionBankFormData.has_calc) {
          this.vmQuestionBankFormData.has_calc = 1
        }
        else {
          this.vmQuestionBankFormData.has_calc = 0
        }
        this.vmQuestionBankFormData.user_id = this.userData.user_id

        if (this.vmQuestionBankFormData.answer_explanation) {
          this.vmQuestionBankFormData.answer_explanation = base64encode(this.vmQuestionBankFormData.answer_explanation)
        }

        let quizBankAddResp = await QuizBanks.quizBankEdit(this, this.vmQuestionBankFormData)
        if (this.isPhotoUploaded) {
          let imageAddResp = await this.imageAdd(this.vmQuestionBankFormData.qbk_id)
          if (imageAddResp && imageAddResp.resp_status) {
            quizBankAddResp.resp_data.image = imageAddResp.resp_data[0].image
          }
          this.isPhotoUploaded = false
        }
        await ApiResponse.responseMessageDisplay(this, quizBankAddResp)

        if (quizBankAddResp && !quizBankAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseQuestionBankEditModal", this.vmQuestionBankFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at quizBankEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * appCategoryDistinct
     */
    async appCategoryDistinct () {
      try {
        let qbkExamCategoryResp = await Apps.appCategoryDistinct(this)
        if (qbkExamCategoryResp && qbkExamCategoryResp.resp_status) {
          this.vmqbkExamCategoryList = qbkExamCategoryResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at quizBankView() and Exception:", err.message)
      }
    },
    /**
     * appCategoryDistinct
     */
    async qbkCategory () {
      try {
        let qbkCategoryResp = await QuizBanks.qbkCategory(this)
        if (qbkCategoryResp && qbkCategoryResp.resp_status) {
          this.vmqbkcategoryList = qbkCategoryResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at qbkCategory() and Exception:", err.message)
      }
    }
  }
}
</script>
